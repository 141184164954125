// Here you can add other styles
.border-12px {
  border: 1px solid #f3f1f1;
  border-radius: 5px;
  padding: 3px;
}
.border-bottom-12px {
  border-bottom: 1px solid #3a3939;
  padding: 5px;
}

.margin-left-2px {
  margin-left: 2px;
}
.margin-left-12px {
  margin-left: 12px;
}
.margin-top-bottom-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.margin-right-2px {
  margin-right: 2px;
}
.margin-right-12px {
  margin-right: 12px;
}
.text-center {
  text-align: center;
}
.cursor-pointer {
  cursor: pointer;
}
.eye-custom:hover {
  border: 1px solid #b9afaf;
}
.font-weight-600 {
  font-weight: 600;
}

.disable {
  display: none;
}
.none_disable {
  display: flex;
}

.border-radius {
  border-radius: 5px;
}
.custom-btn {
  border-radius: 12px;
  // height: 40px;
  margin: auto;
  margin-left: 20px;
  font-weight: 500;
  padding: 6px 16px !important;
  background-color: #1573fe;
  border: none;
}
.custom-btn-sm {
  border-radius: 12px;
  margin-top: 10%;
  font-weight: 500;
  padding: 6px 9px !important;
  background-color: #1573fe;
  border: none;
  font-size: 0.6rem;
}

.custom-btn-header {
  border-radius: 12px;
  //: ;
  height: 40px;
  /* margin: auto; */
  /* margin-left: 20px; */
  font-weight: 500;
  padding: 6px 16px !important;
  background-color: #ffbd35;
  border: none;
}
.pt-100 {
  padding-top: 100px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-100 {
  padding-bottom: 100px;
}
.cart-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0.2rem 0.4rem;
  font-size: 0.8rem;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(25%, -25%);
}
