.card-product {
  background-color: #fff;
  height: 500px;
  min-width: 250px;
  padding: 20px;
  border-radius: 10px;
}
.card-product-header {
  height: 100px;
  border-bottom: 1px solid #333;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.card-product-body-item {
  margin-top: 20px;
}
.firo-title h2 {
  font-family: Segoe UI, Roboto, sans-serif;
  color: #002958;
  font-size: 30px;
  line-height: 1.2;
  font-weight: 400;
  letter-spacing: -2px;
}
.card-product:hover {
  -webkit-box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.1);
  border: 3px solid #f9a32a;
  transform: scale(1.03);
}
