.btn.btn-outline-warning.btn-cus:hover {
  /* Bỏ hiệu ứng hover bằng cách xóa các thuộc tính CSS liên quan */
  background-color: transparent;
  border-color: #ffc107 !important;
  color: #ffc107 !important;
}
.btn.btn-outline-warning.btn-cus:hover:focus {
  /* Bỏ hiệu ứng focus khi hover */
  box-shadow: none !important;
  outline: none !important;
}
