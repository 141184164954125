body {
  font-family: Segoe UI, Roboto, sans-serif !important;
  font-size: 16px;
  line-height: 25px;
  position: relative;
  overflow-x: hidden !important;
  margin: 0 auto;
}
.input-lookup::placeholder {
  /* Style for placeholder text */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.input-lookup {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.disabled-none {
  display: none;
}
.d-table {
  width: 100%;
  height: 100%;
  display: table !important;
}
.d-table-cell {
  display: table-cell;
  vertical-align: middle;
}
.package-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: #00ff00; /* Màu xanh của border left */
}
.package-item-customer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: #a0c9e8; /* Màu xanh của border left */
}
/* Banner CSS Start */
.banner-style-three {
  background-image: url(../../assets/img/rectangle.png);
  background-size: cover;
  background-position: center center;
  height: 615px;
}
.banner-style-three .banner-text {
  max-width: 750px;
}
.banner-style-three .banner-text span {
  font-size: 18px;
  font-weight: 600;
  color: #fd1616;
  margin-bottom: 20px;
  display: inline-block;
}
.banner-style-three .banner-text .background_span {
  font-size: 40px;
  background-color: #eec471;
  color: #fff;
  padding: 0 5px;
}
.banner-style-three .banner-text h6 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
  /* text-transform: capitalize; */
}

.banner-style-three .banner-text p {
  margin-bottom: 30px;
  color: #001935;
}

.banner-style-three .banner-text .theme-btn .default-btn {
  background: #ffffff;
  color: #525252;
}

.banner-style-three .banner-text .theme-btn .default-btn:hover {
  background: #fd1616;
  color: #ffffff;
}

.banner-style-three .banner-text .theme-btn .active {
  background: #fd1616;
  color: #ffffff;
}

.banner-style-three .banner-text .theme-btn .active:hover {
  background: #001935;
}

.cricle-background_banner {
  /* background: rgb(180, 209, 230); */
  padding: 10px;
  border-radius: 10px;
  width: 50%;
  top: 60%;
  position: absolute;
  left: -23%;
}
.customer_background_banner {
  font-size: 1.2rem;
}
.customer_star_banner {
  font-size: 0.7rem;
}

.cricle-background {
  width: 400px;
  position: absolute;
  top: 40%;
  left: -40%;
}
.customer_star {
  position: absolute;
  top: 65%;
  left: 25%;
}
.customer_background {
  position: absolute;
  top: 45%;
  left: 10%;
}

.banner-card {
  box-sizing: border-box;
  position: absolute;
  max-width: 1024px;
  height: 155px;
  /* margin: 0 10%; */
  /* left: 22%; */
  /* top: -1%; */
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  /* Foundation /Grey/Light :hover */
  border: 1px solid #fcfcfc;
  filter: drop-shadow(0px 4px 4px rgba(255, 255, 255, 0.05));
  backdrop-filter: blur(12px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 16px;
}
.layout_cricle-1 {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 32%;
  top: 45%;
  background: #fcc33a;
  border-radius: 50%;
}
.layout_cricle-2 {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 65%;
  top: 44%;
  background: #02d602;
  border-radius: 50%;
}
.banner-card-item {
  padding: 30px;
  text-align: center;
}

.find-section .input-search {
  height: 60px;
}
.input-lookup {
  font-size: 100%;
}
.find-section .btn-search {
  position: absolute;
  right: 2%;
  top: 18%;
  background: #ffbd35;
  border-radius: 16px !important;
  width: 130px;
  height: 40px;
  text-align: center;
  z-index: 1000;
  border: none;
}

.banner-style-three .banner-text span {
  font-size: 18px;
  font-weight: 600;
  color: #fd1616;
  margin-bottom: 20px;
  display: inline-block;
}
.banner-style-three .banner-text .background_span {
  font-size: 40px;
  background-color: #eec471;
  color: #fff;
  padding: 0 5px;
}
.banner-style-three .banner-text h6 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
  /* text-transform: capitalize; */
}

.find-section .input-search {
  height: 60px;
  /* position: relative; */
}
.find-section .btn-search {
  position: absolute;
  right: 2%;
  top: 15%;
  background: #ffbd35;
  /* border-radius: 16px !important; */
  width: 12%;
  height: 50%;
  text-align: center;
  z-index: 1000;
  border: none;
  color: #ffffff;
  font-weight: 600;
}
.find-form {
  background: #ffffff;
  padding: 45px;
  border-radius: 10px;
  margin-top: -75px;
  z-index: 1;
  position: relative;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}
.input-search .dropdown-custom:hover {
  color: #333 !important;
  background-color: #fff !important;
}

/* 
 */

.job-style-two {
  /* background: #fdf8f7; */
  position: relative;
  z-index: 1;
}

.job-style-two::before {
  position: absolute;
  content: "";
  background-image: url(../../assets/img/shape/job.png);
  background-position: center center;
  background-size: cover;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.02;
  z-index: -1;
}
.job-card-two {
  background: #ffffff;
  margin-bottom: 30px;
  padding: 20px 30px;

  border-radius: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  position: relative;
}
.job-card-two-currency {
  border-top-left-radius: 73px;
  border-bottom-right-radius: 25px;
  padding: 10px 41px;
  position: absolute;
  width: 238px;
  height: 41px;
  background: #d2e4f5;
  right: -18px;
  top: -20px;
}
.job-style-two .btn {
  border-radius: 16px !important;
  /* width: 130px; */
  /* height: 40px; */
  margin-left: 10px;
}
.job-card-two .company-logo {
  background: #fddcd4;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  border-radius: 100px;
  border: 1px dashed #fd1616;
  position: relative;
  top: -8px;
}

.job-card-two .job-info {
  padding-left: 25px;
}

.job-card-two .job-info h3 {
  margin-bottom: 10px;
}

.job-card-two .job-info h3 a {
  color: #001935;
}

.job-card-two .job-info h3 a:hover {
  color: #fd1616;
}

.job-card-two .job-info ul {
  padding-left: 0;
}

.job-card-two .job-info ul li {
  display: inline-block;
  font-size: 15px;
  padding-right: 10px;
}

.job-card-two .job-info ul li a {
  color: #fd1616;
  font-weight: 500;
}

.job-card-two .job-info ul li i {
  top: 2px;
  position: relative;
  font-size: 18px;
}

.job-card-two .job-info span {
  /* background: #fdd6cc; */
  display: inline-block;
  padding: 5px 10px;
  /* color: #fd1616; */
  margin-bottom: 10px;
  /* border: 1px dashed #fd1616; */
  font-size: 14px;
}

.job-card-two .theme-btn .default-btn {
  margin-right: 0;
}

.main-info .main-info-title {
  width: 380px;

  font-weight: 700;
  font-size: 28px;
  line-height: 50px;

  color: #000000;
}
.main-info .main-info-content {
  width: 80%;
  height: 124px;
  font-weight: 400;
  font-size: 18px;
  line-height: 140.5%;
  /* or 31px */

  color: #000000;
}

.main-info-support {
  /* border: 1px solid #e3e3e3; */
  height: 50px;
  position: relative;
  width: 65%;
  border-radius: 10px;
}
.main-info-support-input {
  border: none;
  border-top-left-radius: 16px;

  border-bottom-left-radius: 16px;
  margin-right: 20px;
}
.main-info-support-submit {
  position: absolute;
  right: 2%;
  top: 15%;
  border-radius: 10px !important;
  width: 130px;
  height: 35px;
  text-align: center;
  z-index: 9999999;
  border: none;
}
.main-info-support .btn-info-support {
  position: absolute;
  right: 2%;
  top: 14%;
  background: #1573fe;
  /* border-radius: 16px !important; */
  width: 30%;
  height: 52%;
  text-align: center;
  z-index: 1000;
  border: none;
  color: #ffffff;
  font-weight: 600;
}
.main-info-support .input-search {
  height: 50px;
}

/* Footer CSS Start */
.footer-area {
  background: #53718b;
  position: relative;
}

.footer-area::before {
  position: absolute;
  content: "";
  background-image: url(../../assets/img/shape/footer.png);
  background-position: center center;
  background-size: cover;
  width: 140px;
  height: 138px;
  right: 60px;
  top: 70px;
  opacity: 0.2;
  -webkit-animation: rotate 30s infinite linear;
  animation: rotate 20s infinite linear;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer-area .footer-widget {
  margin-bottom: 30px;
}

.footer-area .footer-widget .footer-logo {
  margin-bottom: 30px;
}

.footer-area .footer-widget p {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 30px;
}

.footer-area .footer-widget .footer-social a i {
  color: #3955bc;
  background: #ffffff;
  font-size: 18px;
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 50px;
  line-height: 35px;
  margin-right: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.footer-area .footer-widget .footer-social a i:hover {
  background: #f1e20a;
  color: #ffffff;
}

.footer-area .footer-widget h3 {
  color: #ffffff;
  position: relative;
  margin-bottom: 40px;
  font-weight: 600;
}

.footer-area .footer-widget h3::before {
  position: absolute;
  content: "";
  background: #ffffff;
  height: 2px;
  width: 50px;
  left: 0;
  bottom: -15px;
}

.footer-area .footer-widget ul {
  padding-left: 0;
  margin-bottom: 0;
}

.footer-area .footer-widget ul li {
  list-style-type: none;
  color: #d3d3d3;
  margin-bottom: 5px;
}

.footer-area .footer-widget ul li a {
  color: #d3d3d3;
}

.footer-area .footer-widget ul li a i {
  color: #f1e20a;
  top: 2px;
  position: relative;
}

.footer-area .footer-widget ul li a:hover {
  color: #f1e20a;
}

.footer-area .footer-widget ul li:last-child {
  margin-bottom: 0;
}

.footer-area .footer-widget.footer-info ul li {
  margin-bottom: 12px;
}

.footer-area .footer-widget.footer-info ul li span {
  display: block;
}

.footer-area .footer-widget.footer-info ul li span i {
  color: #f1e20a;
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.footer-area .footer-widget.footer-info ul li a {
  padding-left: 25px;
}

.footer-area .footer-widget.footer-info ul li:last-child {
  padding-left: 25px;
}

.footer-area .footer-widget.footer-info ul li:last-child span {
  left: -25px;
  position: relative;
}

.footer-area .footer-widget.pl-60 {
  padding-left: 60px;
}

.copyright-text {
  background: #03143b;
  padding: 20px 0;
}

.copyright-text p {
  margin-bottom: 0;
  color: #ffffff;
  line-height: 1;
}

.copyright-text p a {
  color: #ffffff;
}

.copyright-text p a:hover {
  color: #f1e20a;
}

/* Footer CSS End */
/*---------- Home Page One CSS End ----------*/
/*---------- Home Page Two CSS Start ----------*/
/* Banner CSS Start */
.banner-style-two {
  background-image: url(../../assets/img/banner/2.jpg);
  background-position: center center;
  background-size: cover;
  height: 800px;
  position: relative;
  z-index: 0;
}

.banner-style-two::before {
  position: absolute;
  content: "";
  background: rgba(1, 12, 41, 0.85);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.banner-style-two .banner-text {
  max-width: 650px;
}

.banner-style-two .banner-text span {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 25px;
}

.banner-style-two .banner-text h1 {
  color: #ffffff;
  font-size: 65px;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.banner-style-two .banner-text p {
  color: #ffffff;
  margin-bottom: 45px;
}

.banner-style-two .banner-img {
  position: absolute;
  top: 15%;
  right: 5%;
  max-width: 500px;
}

.banner-style-two .banner-img img {
  border-radius: 65% 35% 42% 58% / 55% 48% 52% 45%;
  -webkit-animation: wrap 5s infinite alternate;
  animation: wrap 5s infinite alternate;
}
.footer-area .footer-widget ul li a i {
  color: #f1e20a;
  top: 2px;
  position: relative;
}
.top-btn.active {
  bottom: 2%;
}
.top-btn {
  position: fixed;
  bottom: 102%;
  right: 30px;
  color: #ffffff;
  font-size: 30px;
  background: #0ad2e0;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50px;
  cursor: pointer;
  -webkit-transition: 2s;
  transition: 2s;
  z-index: 999;
}
.notices {
  min-height: 150px;
  background-color: #ffffff;
  width: 60%;
  border-radius: 20px;
  padding: 10px;
  padding-top: 1%;
}
/* Styles for large screens */
@media (max-width: 1024px) {
  .notices {
    min-height: 150px;
    background-color: #ffffff;
    width: 70%;
    border-radius: 20px;
    padding: 10px;
    padding-top: 1%;
  }
  .d-table-cell .container {
    /* margin-top: -30% !important; */
  }
  .banner-style-three .banner-text h6 {
    font-size: 25px;
  }
  .cricle-background {
    width: 400px;
    position: absolute;
    top: 55%;
    left: -25%;
  }
  .cricle-background img {
    max-width: 50% !important;
  }
  .customer_background {
    font-size: 1rem !important;
  }
  .customer_star {
    position: absolute;
    top: 65%;
    left: 12%;
    font-size: 0.8rem;
  }
  .banner-card {
    box-sizing: border-box;
    position: absolute;
    max-width: 720px;
    height: 178px;
    margin-top: 10%;
    /* left: 22%; */
    /* top: -1%; */
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border: 1px solid #fcfcfc;
    filter: drop-shadow(0px 4px 4px rgba(255, 255, 255, 0.05));
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    border-radius: 16px;
  }
  .main-info .main-info-title {
    width: 380px;
    /* font-family: "Lexend Deca";
    font-style: normal; */
    font-weight: 700;
    font-size: 30px;
    line-height: 50px;
    color: #000000;
  }
  .main-info .main-info-content {
    width: 100%;
    height: 124px;

    font-weight: 400;
    font-size: 20px;
    line-height: 140.5%;
    color: #000000;
  }
  .main-info-support .btn-info-support {
    position: absolute;
    right: 6%;
    top: 13%;
    background: #1573fe;
    /* border-radius: 16px !important; */
    width: 30%;
    height: 52%;
    text-align: center;
    z-index: 1000;
    border: none;
    color: #ffffff;
    font-weight: 600;
  }
  .footer-area .footer-widget h3 {
    color: #ffffff;
    position: relative;
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 18px;
  }
}

/* Styles for medium screens */
@media (min-width: 768px) and (max-width: 991px) {
  .notices {
    min-height: 150px;
    background-color: #ffffff;
    width: 90%;
    border-radius: 20px;
    padding: 10px;
    padding-top: 1%;
  }
  .job-card-two .company-logo {
    background: #fddcd4;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 100px;
    border-radius: 100px;
    border: 1px dashed #fd1616;
    position: relative;
    top: -8px;
  }
  .job-card-two .company-logo a img {
    width: 30px !important;
    height: 30px !important;
    margin-top: -46px;
  }
  .find-section .btn-search {
    position: absolute;
    right: 4%;
    top: 15%;
    background: #ffbd35;
    /* border-radius: 16px !important; */
    width: 16%;
    height: 50%;
    text-align: center;
    z-index: 1000;
    border: none;
    color: #ffffff;
    font-weight: 600;
  }
  .dropdown-custom {
    width: 20% !important;
  }
  .main-info .main-info-title {
    width: 310px;
    font-weight: 700;
    font-size: 30px;
    line-height: 50px;
    color: #000000;
  }
  .main-info .main-info-content {
    width: 100%;
    height: 124px;
    font-family: "Lexend Deca";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140.5%;
    color: #000000;
  }
  .main-info-support {
    margin-top: 10px;
    /* border: 1px solid #e3e3e3; */
    height: 50px;
    position: relative;
    width: 100%;
    border-radius: 10px;
  }
  .cricle-background_banner {
    /* background: rgb(180, 209, 230); */
    padding: 10px;
    border-radius: 10px;
    width: 80% !important;
    top: 65%;
    position: absolute;
    left: -30%;
  }
  .customer_background_banner {
    font-size: 1rem;
  }
  .customer_star_banner {
    font-size: 0.7rem;
  }
  .cricle-background_banner {
    /* background: rgb(180, 209, 230); */
    padding: 10px;
    border-radius: 10px;
    width: 60% !important;
    top: 40%;
    position: absolute;
    left: 0%;
  }
}

/* Styles for small screens */
@media (max-width: 768px) {
  .notices {
    min-height: 150px;
    background-color: #ffffff;
    width: 100%;
    border-radius: 20px;
    padding: 10px;
    padding-top: 1%;
  }
  .notices h6 {
    font-size: 0.9rem;
  }
  .item-checkbox-payment {
    margin: -16% -8% !important;
  }
  .input-lookup {
    font-size: 70%;
  }
  .cricle-background_banner {
    /* background: rgb(180, 209, 230); */
    padding: 10px;
    border-radius: 10px;
    width: 75%;
    top: 55%;
    position: absolute;
    left: 0%;
  }
  .banner-style-three .banner-text h6 {
    font-size: 18px;
  }
  .banner-style-three .banner-text .background_span {
    font-size: 25px;
    background-color: #eec471;
    color: #fff;
    padding: 0 5px;
  }
  .banner-card {
    box-sizing: border-box;
    position: absolute;
    max-width: 90%;
    height: 240px;
    margin-top: 10%;
    /* left: 22%; */
    /* top: -1%; */
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border: 1px solid #fcfcfc;
    filter: drop-shadow(0px 4px 4px rgba(255, 255, 255, 0.05));
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    border-radius: 16px;
  }
  .find-section .input-search {
    height: 45px;
    /* position: relative; */
  }
  .find-section .btn-search {
    position: absolute;
    right: 4%;
    top: 12%;
    background: #ffbd35;
    /* border-radius: 16px !important; */
    width: 26%;
    height: 55%;
    text-align: center;
    z-index: 1000;
    border: none;
    color: #ffffff;
    font-weight: 600;
    font-size: 0.8rem;
  }
  .cricle-background {
    width: 390px;
    position: absolute;
    top: 44%;
    left: -8%;
  }
  .find-form .input-search btn {
    width: 20% !important;
  }
  .job-card-two .company-logo {
    background: #fddcd4;
    width: 50px;
    height: 50px;
    text-align: center;
    /* line-height: 100px; */
    border-radius: 100px;
    border: 1px dashed #fd1616;
    position: relative;
    top: -8px;
  }
  .job-card-two .company-logo a img {
    width: 30px !important;
    height: 30px !important;
    margin-top: -46px;
    display: none;
  }
  .dropdown-custom {
    width: 30% !important;
  }
  .main-info .main-info-title {
    width: 380px;
    font-weight: 700;
    font-size: 20px;
    line-height: 50px;
    color: #000000;
  }
  .main-info .main-info-content {
    width: 100%;
    height: 155px;
    font-weight: 400;
    font-size: 18px;
    line-height: 140.5%;
    color: #000000;
  }
  .main-info-support {
    height: 50px;
    position: relative;
    width: 100%;
    border-radius: 10px;
    margin-top: 10px !important;
  }
  /* .disabled-none {
    display: block !important;
  } */
}

/* Styles for medium screens */
@media (max-width: 425px) {
  .input-group .btn {
    font-size: 50% !important;
  }
  .find-form {
    padding: 20px;
  }
  .disabled-none {
    display: block !important;
  }
  .input-lookup {
    font-size: 50%;
  }

  .banner-card {
    box-sizing: border-box;
    position: absolute;
    max-width: 90% !important;
    height: 185px;
    margin-top: 10%;
    /* left: 22%; */
    /* top: -1%; */
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border: 1px solid #fcfcfc;
    filter: drop-shadow(0px 4px 4px rgba(255, 255, 255, 0.05));
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    border-radius: 16px;
  }
  .cricle-background_banner {
    /* background: rgb(190 219 241); */
    padding: 10px;
    border-radius: 10px;
    width: 100% !important;
    top: 25%;
    position: absolute;
    left: -8%;
  }
  .customer_background_banner {
    font-size: 0.9rem;
  }
  .customer_star_banner {
    font-size: 50%;
  }
  .cricle-background {
    width: 270px;
    position: absolute;
    top: 20%;
    left: -20%;
  }
  .customer_background {
    font-size: 0.6rem !important;
  }
  .customer_star {
    position: absolute;
    top: 65%;
    left: 12%;
    font-size: 0.5rem;
  }
  .banner-card-item h3 {
    font-size: 0.8rem !important;
  }
  .banner-card-item span {
    font-size: 0.7rem !important;
  }
  .banner-card-item {
    padding: 16px;
    text-align: center;
  }
  .layout_cricle-1 {
    position: absolute;
    width: 7px;
    height: 23px;
    left: 30%;
    top: 45%;
    background: #fcc33a;
    border-radius: 50%;
  }

  element.style {
  }
  .layout_cricle-2 {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 64%;
    top: 44%;
    background: #02d602;
    border-radius: 50%;
  }
  .find-section .input-search {
    height: 40px;
    /* position: relative; */
  }
  .find-section .btn-search {
    position: absolute;
    right: 6%;
    top: 12%;
    background: #ffbd35;
    /* border-radius: 16px !important; */
    width: 25%;
    height: 50%;
    text-align: center;
    z-index: 1000;
    border: none;
    color: #ffffff;
    font-weight: 600;
    font-size: 0.6rem;
  }
  .dropdown-custom {
    width: 33% !important;
    font-size: 0.7rem;
  }

  .job-card-two .job-info ul {
    padding-left: 0;
    text-align: center;
  }
  .company-logo {
    display: none;
  }
  .item-checkbox-payment {
    margin: -30% -12% !important;
  }
  .main-info i {
    margin: 0px 40% !important;
  }
  .footer-area .footer-widget.pl-60 {
    padding-left: 0px !important;
  }
  .main-info .main-info-title {
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    line-height: 50px;
    color: #000000;
  }
  .main-info .main-info-content {
    width: 100%;
    height: 155px;
    font-weight: 400;
    font-size: 15px;
    line-height: 140.5%;
    color: #000000;
  }
  /* .banner-style-three {
    height: 810px !important;
  } */
  .d-table-cell .container {
    /* margin-top: -90% !important; */
  }
  .main-info-support {
    /* border: 1px solid #e3e3e3; */
    height: 50px;
    position: relative;
    width: 100%;
    margin-top: 40px !important;
    border-radius: 10px;
  }
}

/* Max-960 Mobile */
